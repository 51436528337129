<script>
import centralEventBus from "../views/customize/component/EditorComponents/centralEventBus";
export default {
    props:['currElementIndex','deleteCondition','currElementType'],
  data() {
    return {
       
    };


    //   this.contentLocal = this.content;
  },
  methods: {
    deleteElement(){
        let index =this.currElementIndex
        if(index > -1){
            centralEventBus.$emit("deleteBlock", index);
        }
    },
    duplicateElement(){
        let index =this.currElementIndex
        if(index > -1){
            centralEventBus.$emit("handleDuplicate", index);
        }
    },
    shiftElement(direction){
        let index =this.currElementIndex
        if(index > -1){
            let obj = {
                direction:direction,
                index:index
            }
            centralEventBus.$emit("shiftEditorElement", obj);
        }
    }
    
  },
  computed: {
   allowedElementsDuplicates(){
    return ['text','html']
   },
   isElementDuplicatable(){
    return this.allowedElementsDuplicates.includes(this.currElementType)
   }
  },
};
</script>

<template>


<div class="actionWrapper">
    <div class="d-flex align-items-center">

        <div class="actionBtn " @click.stop="shiftElement('up')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 2.66666V13.3333M8 2.66666L12 6.66666M8 2.66666L4 6.66666" stroke="#B3AFB6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
        <div class="actionBtn "  @click.stop="shiftElement('down')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 13.3333L8 2.66666M8 13.3333L4 9.33332M8 13.3333L12 9.33332" stroke="#B3AFB6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
        <div class="actionBtn" v-if="isElementDuplicatable" @click.stop="duplicateElement()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M11.6667 9.33333H12.6667C13.403 9.33333 14 8.73638 14 8V3.33333C14 2.59695 13.403 2 12.6667 2H8C7.26362 2 6.66667 2.59695 6.66667 3.33333V4.33333M3.33333 6.66667H8C8.73638 6.66667 9.33333 7.26362 9.33333 8V12.6667C9.33333 13.403 8.73638 14 8 14H3.33333C2.59695 14 2 13.403 2 12.6667V8C2 7.26362 2.59695 6.66667 3.33333 6.66667Z" stroke="#B3AFB6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
        <div class="actionBtn "  v-if="deleteCondition" @click.stop="deleteElement()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M12 4V12C12 12.7364 11.403 13.3333 10.6666 13.3333H5.33329C4.59691 13.3333 3.99996 12.7364 3.99996 12V4M2.66663 4H13.3333M9.99996 4V3.33333C9.99996 2.59695 9.40301 2 8.66663 2H7.33329C6.59691 2 5.99996 2.59695 5.99996 3.33333V4" stroke="#B3AFB6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    </div>
</div>

</template>

<style scoped>
.actionWrapper{
    position:absolute;
    bottom:-49px;
    right:-2px;
    z-index:1000;
    border-radius: 8px;
    border: 1px solid #D2D8E0;
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(24, 25, 28, 0.10);
}
.actionWrapper .actionBtn{
    display:flex;
    align-items:center;
    justify-content:center;
   padding:12px;
   cursor: pointer;
}
.actionWrapper .actionBtn:first-child {
 border-top-left-radius: 8px;
 border-bottom-left-radius: 8px;
}
.actionWrapper .actionBtn:last-child {
 border-top-right-radius: 8px;
 border-bottom-right-radius: 8px;
}
.actionWrapper .actionBtn:not(:last-child) {
    border-right: 1px solid #D2D8E0;
}

.actionWrapper .actionBtn svg{
    width:20px;
    height:20px;
}

</style>

